import React from 'react';

import "../style.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';

const LandingBG = () => {
    return(
        <>
            <div id="mainBg">
                <Fade Up>
                    <h1 style={{fontSize:"10vh",paddingTop:"30vh"}}>Catie Shadic</h1>
                </Fade>
                <Fade Down delay={1000}>
                    <h2>"She ain't your everyday Gal"</h2>
                </Fade>
                <div style={{textAlign:"center"}} class="ArrowContainer">
                    <div class="chevron"></div>
                    <div class="chevron"></div>
                    <div class="chevron"></div>
                </div>
            </div>
            <hr style={{border:"1px solid white"}}></hr>
        </>
    )
}

export default LandingBG