import React from 'react';
import ReactDOM from 'react-dom';

import LandingBG from "./components/Landing"
import Skill from "./components/Skills"
import Quote from "./components/Quotes"
import FAQ from "./components/FAQ"

import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


const Main = () => {
  return(
    <>
    <Router>
      <Switch>
          <Route path="/">
            <LandingBG/>
            <Skill/>
            <Quote/>
            <FAQ/>
            <div id="footer">
              <h6>Still have doubts?</h6>
              <br></br>
              <h6>Copyrights all right reserved by me, Catie the great. The superior blood race.</h6>
            </div>
          </Route>
        </Switch>
      </Router>
    </>
  )
}

ReactDOM.render(<Main/>,document.getElementById('root'))