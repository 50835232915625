import React from 'react';

import "../style.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import catie from "../media/catie.JPG"
import Fade from 'react-reveal/Fade';

const FAQ = () => {
    return(
        <div style={{paddingTop:"5vh"}} id="faqBox">
            <h1>~ FAQ ~</h1>
            <br></br>
            <div className="container">
                <div className="row">
                    <div className="col-sm" style={{textAlign:'left'}}>
                        <h4>Favorite Color?</h4>
                        <br></br>
                        <h6 id="greenAnimate">- Green</h6>
                        <br></br>
                        <br></br>
                        <h4>Favorite Animal</h4>
                        <br></br>
                        <h6>- Clearly you are not paying attention, go up and read it.</h6>
                        <br></br>
                        <br></br>
                        <h4>Most attractive feature from the opposite gender?</h4>
                        <br></br>
                        <h6>- High pitch voice, also don't even try.</h6>
                        <br></br>
                        <br></br>
                        <h4>Hometown?</h4>
                        <br></br>
                        <h6>- None of your business, somewhere in the country</h6>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>
                    <div className="col-sm" style={{textAlign:'right'}}>
                        <h4>Favorite bending </h4>
                        <br></br>
                        <h6>Earth Bending</h6>
                        <br></br>
                        <br></br>
                        <h4>Wizarding House?</h4>
                        <br></br>
                        <h6>Gryffindor, and my wand is longer than yours. Also never watched harry potter but I am considering it</h6>
                        <br></br>
                        <br></br>
                        <h4>How to get me not be mad at you?</h4>
                        <br></br>
                        <h6>Better solution, don't get me mad.</h6>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ