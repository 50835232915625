import React from 'react';

import "../style.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import catie from "../media/catie.JPG"
import Fade from 'react-reveal/Fade';

const Quote = () =>{
    return(
        <>
            <div style={{paddingTop:"5vh"}} id="quoteBox">
                <h3>~ Here's what some of Catie's admirers have to say about her ~</h3>
                <br></br>
                <div className="row" style={{textAlign:"left"}}>
                    <div className="col-sm quote">
                        <Fade left>
                            <h5>I have known catie since my start of sophomore year of college, I have to say, in such short time she has really become a very close friend of mine. She is super caring and super nice even though she denys it. Always there to help and most importantly will curse at you if you take the uber. Also for the record she promised me an xbox palace in exchange for a McFlurry.</h5>
                        </Fade>
                        <br></br>
                        <Fade up>
                            <h6 className="quoteAuth" style={{textAlign:"right"}}>- Buttface</h6>
                        </Fade>
                    </div>
                    <div className="col-sm quote">
                        <Fade left>
                            <h5>Catie is a nice person. She is a good friend. She likes elephants which is cool. Whenever I see an elephant I think of her. I am very glad that I have met her this past year. She makes me laugh and I am jealous of her cooking skills.</h5>
                        </Fade>
                        <br></br>
                        <Fade up>
                            <h6 className="quoteAuth" style={{textAlign:"right"}}>- The RA who lives across Garett</h6>
                        </Fade>
                    </div>
                    <div className="col-sm quote">
                        <Fade left>
                            <h5>Thank you for making working south this year fun. Can't wait for many more fun years of memories. Looking forward to many more days of you hearing my alarm.`</h5>
                        </Fade>
                        <br></br>
                        <Fade up>
                            <h6 className="quoteAuth" style={{textAlign:"right"}}>- TJ's least favorite person</h6>
                        </Fade>
                    </div>
                    <div className="col-sm quote">
                        <Fade left>
                            <h5>Catie is the nicest RA here. She is a valueable asset for South Campus. South Campus needs her</h5>
                        </Fade>
                        <br></br>
                        <Fade up>
                            <h6 className="quoteAuth" style={{textAlign:"right"}}>- Unknown / -Chris Bragdon / -Brian Haggerty / -Satish K Tripathi</h6>
                        </Fade>
                    </div>
                </div>
            </div>
            <hr style={{border:"1px solid white"}}></hr>
        </>
    )
}

export default Quote