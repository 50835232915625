import React from 'react';

import "../style.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import catie from "../media/catie.JPG"
import Fade from 'react-reveal/Fade';

import {
    HashRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  

const Skill = () => {
    return(
        <>
                <div style={{paddingTop:"5vh"}} id="SkillBox" className="row">
                    <Fade left>
                        <div style={{textAlign:"center"}} className="col-sm-4">
                            <img id="catie" style={{height:"50vh",borderRadius:"10%",border:"5px solid black"}} src={catie}></img>
                        </div>
                    </Fade>
                    <div style={{textAlign:"left",paddingTop:"8vh"}} className="col-sm-8">
                        <Fade right>
                            <h2>Hello,</h2>
                            <br></br>
                            <h5>I am Catie, and do not, sit on my chair. I am from the <strong>Country</strong>, not the <i>Suburbs</i>. My high school was probably better than yours. My favorite animal is Elephants. Fun fact, I actually own an elephant. her name is Ziwadi. I also own 2 cats, 1 dog and 2 fish. My favorite time period is 1940s. Don't mess with me, trust me, you don't want me as your enemy.</h5>
                        </Fade>
                        <br></br>
                        <Fade left>
                            <hr style={{border:"1px solid white"}}></hr>
                        </Fade>
                        <Fade up>
                            <div id="ballHolderaAYEOO">
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdY4e8NndptlQYTAod1oZgBF6AHP1GMhb1YUoMX2UxnK42WcA/viewform?vc=0&c=0&w=1&flr=0&gxids=7628"><div style={{backgroundColor:"#212529"}} className="redOpt">BFF Quiz</div> </a>
                                <a href= "https://www.instagram.com/sheldricktrust/"><div style={{backgroundColor:"#6C757D"}} className="redOpt">Ziwadi</div></a>
                            </div>
                        </Fade>
                        <Fade right>
                            <hr style={{border:"1px solid white"}}></hr>
                        </Fade>
                    </div>
                </div>
        </>
    )
}

export default Skill